import Footer from '../components/Footer_2'
import IndustryItem from '../components/IndustryItem'
import hero from '../utils/hero'
import Hero from '../components/Hero'
import { useTranslation } from 'react-i18next';
import {useState, useEffect}   from 'react';
import *as axios from 'axios';
import CDN from '..';

export default function ChemicalIndustry() {
	const [titles,setTitles]=useState()
	useEffect(()=>{
					axios.get('get/project-types').then(res=>{
									setTitles(res.data.data[2])
					})
	},[])

    return ( 
        <div>
								{titles?.title && <Hero text={titles.title} img={CDN + titles.image || hero.mining_industry.img} bottom={hero.mining_industry.bottom} />}
								{titles?.data && <IndustryItem data={titles.data}/>}
            <Footer />
        </div>
    )
}

 