import styles from '../styles/IndustryItem.module.css';
import bottom_rectangle from '../assets/images/bottom-rectangle-2.svg'
import React, { useState } from 'react';
import CDN from '..';

export default function IndustryItem({ data, openImage }) {
    const [isOpen, setState] = useState(false);
    const [image,setImage]=useState();
    const showImage = (image) => {
        setState(true);
        setImage(image);
    };
    const hideLightBox = () => {
        setState(false);
    };
    return (
        <section className={styles.industry_item}>
            <div className={`container ${styles.industry_item_inner}`}>
                {data.map((data, index) => (
                    <div key={index} className={styles.project}>
                        <div className={styles.project_left}>
                            <h2 className={styles.project_title} dangerouslySetInnerHTML={{ __html: data.title }} />
                            <p className={styles.project_descr} dangerouslySetInnerHTML={{ __html: data.description }} />
                        </div>
                        <button disabled={!openImage} onClick={() => showImage(data)} className={`${openImage&&styles.showImg} ${styles.project_right}`}>
                            <img src={CDN + data.image}></img>  
                        </button>
                    </div> 
                ))}
            </div>{
                isOpen && (
                    <div className={styles.lightbox} onClick={() => hideLightBox()}>
                        <img src={CDN + image.image} />
                    </div>
                ) 
            } 
            <img className={styles.bottom_rectangle} src={bottom_rectangle} alt={'rectangle'} />
        </section>
    );
} 