import Footer from '../components/Footer_2';
import ResourcesMain from '../components/ResourcesMain';
import React, {Component, useEffect, useState} from 'react';
import hero from '../utils/hero';
import * as axios              from 'axios';
import Hero from '../components/Hero';
import {useTranslation} from 'react-i18next';
import CDN from '..';

export default function Resources() {
  const {t, i18n} = useTranslation();
  const [ResourseData, setData] = useState([]);

  async function fetchData() {
		await axios
      .get('get/resource')
      .then((res) => {
        setData(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }
  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0)
  }, []);
  console.log("Resources", ResourseData.data)
  return (
    <>
      {ResourseData?.first && <Hero text={ResourseData.first.title} img={CDN + ResourseData.first.image} bottom={hero.resources.bottom} />}
      {ResourseData?.data && <ResourcesMain data={ResourseData.data.sort((a, b) => a.id < b.id ? 1 : -1)}/>}
      <Footer />
    </>
  );
}
