import React from 'react';
import styles from '../styles/ResourcesMain.module.css';
import slider from '../assets/images/slider-1.png';
import bottom_rectangle from '../assets/images/bottom-rectangle-2.svg';
import ShowMore from './ShowMore';
import Slider from './SlickSlider';
import CDN from '..';
 
export default function ResourcesMain({data}) {
  return (
    <section className={styles.resources}>
      <div className='container'>
        <div className={styles.resources_inner}>
          {data.map((item, index) => ( 
            <div className={styles.resources_main} key={index}>
              <div className={styles.resources_text}><h2 className={styles.resources_title} dangerouslySetInnerHTML={{__html: item.title}} />
              <p className={styles.resources_descr} dangerouslySetInnerHTML={{__html: item.description}} /></div>
              <div className={styles.resources_img}><img src={CDN+item.image}/></div>
            </div> 
          ))} 
          <Slider /> 
        </div>
      </div>
      <img className={styles.bottom_rectangle} src={bottom_rectangle} alt={'img'}/>
    </section>
  );
}
 