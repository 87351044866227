import React from 'react';
import styles from '../styles/HistoryDescr_1.module.css';
import SlickSlider from './SlickSlider';
import ShowMore from './ShowMore';
import bottom_rectangle             from '../assets/images/bottom-rectangle-2.svg';

export default function HistoryDescr_1({data}) {
  return (
    <section className={styles.history_descr}>
      <div className={`container ${styles.history_descr_inner}`}>
        <div className={styles.history_descr_text}>
          {data.data[2].descriptions.map((desc, index) => (
            <p key={index} className={styles.history_descr_paragraph} dangerouslySetInnerHTML={{__html: desc}} />
          ))}
        </div>
        <SlickSlider images={data.sliders} />
      </div>
      <img className={styles.bottom_rectangle} src={bottom_rectangle} alt='img' />
    </section> 
  );
}
