import React from 'react'
import styles from '../styles/Slider.module.css'
import slider_1 from '../assets/images/slider-img-1.png'
import slider_2 from '../assets/images/slider-img-2.png'
import slider_3 from '../assets/images/slider-img-3.png'
import slider_4 from '../assets/images/slider-img-4.png'
import slider_bottom from '../assets/images/slider-bottom.svg'
import slider_left_lines from '../assets/images/slider-left-lines.svg'
import slider_right_lines from '../assets/images/slider-right-lines.svg'
import slider_left_button from '../assets/images/slider-left-button.svg'
import slider_right_button from '../assets/images/slider-right-button.svg'
import slider_left_triangle from '../assets/images/slider-left-triangle.png'
import slider_right_triangle from '../assets/images/slider-right-triangle.png'
import {Link}                       from 'react-router-dom';
import { useState, useEffect } from "react"
import *as axios from 'axios';
import CDN from '..'


export default function Slider() {
    const [slider,setSlider]=useState()
    useEffect(()=>{
        axios.get('get/slider').then(res=>{
            setSlider(res.data.data)
        })
    },[])
    const links=['mining-industry','metallurgy-and-enrichment-industry','chemical-industry','reviews']
    return (
        <div id="carouselExampleControls" className={`${styles.carousel} carousel slide`} data-bs-ride="carousel">
            <img className={styles.slider_bottom} src={slider_bottom} />
            <img className={styles.slider_left} src={slider_left_triangle} alt="" />
            <img className={styles.slider_right} src={slider_right_triangle} alt="" />
            <img className={styles.slider_left_lines} src={slider_left_lines} />
            <img className={styles.slider_right_lines} src={slider_right_lines} />
            <div className="carousel-inner"> 
                {slider&&slider.map((slide, index)=>(
                    <Link className={`carousel-item ${index===0&&'active'}`} to={`${links[index]}`}>
                        <img src={CDN+slide.image} className="d-block w-100" alt="slider" />
                        <p className={styles.carousel_item_descr} dangerouslySetInnerHTML={{__html: slide.big_title}}></p>
                        <p className={styles.carousel_item_title}>{slide.translate}</p>
                    </Link> 
                )      
                )}
            </div>
            <button className={styles.button_left} type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                <img src={slider_left_button} alt="button" />
            </button>
            <button className={styles.button_right} type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                <img src={slider_right_button} alt="button" />
            </button>
        </div>
    )
}
