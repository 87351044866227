import React, { useEffect, useState, useRef } from 'react';
import { Link, BrowserRouter as Router } from 'react-router-dom';
import styles from '../styles/Footer_1.module.css';
import logo from '../assets/icons/logo.svg';
import twogis from '../assets/2gis.png';
import instagram from '../assets/icons/footer-instagram-icon.svg';
import { useTranslation } from 'react-i18next';
import { translatedTexts } from '../translatedTexts';
import emailjs from '@emailjs/browser';
import styled from 'styled-components';

export default function Footer() {
  const url=window.location.pathname;
  let CustomFooter;
  if(url==='/about_company'||url==='/history'||url==='/management'||url==='/partners'||url==='/resources'||url==='/vacancies'){
    CustomFooter = styled.footer`
    background-color: #0A4689;
    color:#fff !important;
    #footer_bottom{
      background-color: #7894B4;
    } 
    `
  }
  else{
    CustomFooter = styled.footer`
    `
  }
  const { t, i18n: { language: lang } } = useTranslation();
  const [address, setAddress] = useState(null);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const formMessage = useRef();
  const formPhone = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_6wz44i7', 'template_biuhaek', formMessage.current, '_Nt-SrtI2d_qLDnAn').then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
  };

  const sendPhone = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_6wz44i7', 'template_n9n0o1n', formPhone.current, '_Nt-SrtI2d_qLDnAn').then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
  };
  async function fetchContacts() {
    const url = 'https://back-kgcm.kgcm.kz/api/get/contacts';
    const response = await fetch(url);
    if (response.ok) {
      const result = await response.json();
      console.log('footer', result);
      setPhone(result.data.find((item) => item.type === 'phone'));
      setEmail(result.data.find((item) => item.type === 'url'));
      setAddress(result.data.find((item) => item.type === 'address'));
    }
  }

  useEffect(() => {
    fetchContacts();
  }, []);
  return ( 
    // dangerouslySetInnerHTML={{ __html: address.title[lang] }}
    <CustomFooter id="footer" className={styles.footer}>
      <div className="container">
        <Link to="/" className={styles.logo}>
          <img src={translatedTexts[lang].logotip}/>
        </Link>
        <ul className={styles.footer_top}>
          {address && <li class={`${styles.liClass} ${styles.map}`}><a className={`${styles.footer_top_link} ${styles.map}`}
            href="https://2gis.kz/ustkam/firm/12807639721316142?m=82.598275%2C49.971791%2F17.22"
            dangerouslySetInnerHTML={{__html: address.title[lang]}}
           >
          </a>
            {phone &&
              <button type="button" className={`${styles.footer_top_btn} ${styles.phone}`} data-bs-toggle="modal"
                data-bs-target="#modal-4" data-bs-whatever="@mdo"
                dangerouslySetInnerHTML={{ __html: phone.title[lang] }}>
              </button>}
            <div className="modal fade" id="modal-4" tabIndex="-1" aria-labelledby="exampleModalLabel"
              aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title"
                      id="exampleModalLabel">{translatedTexts[lang].contacts.leaveAContacts}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal"
                      aria-label="Close" />
                  </div>
                  <div className="modal-body">
                    <form ref={formPhone} onSubmit={sendPhone}>
                      <div className="mb-3">
                        <input placeholder="ФИО" type="text" className="form-control"
                          id="recipient-name" />
                      </div>
                      <div className="mb-3">
                        <textarea placeholder="Номер телефона" className="form-control"
                          id="message-text" />
                      </div>
                    </form>
                  </div>
                  <div className="modal-footer">
                    <button type="submit" className="btn btn-primary">{translatedTexts[lang].submit}</button>
                  </div>
                </div>
              </div>
            </div>
            {email &&
              <button type="button" className={`${styles.footer_top_btn} ${styles.letter}`} data-bs-toggle="modal"
                data-bs-target="#modal-3" data-bs-whatever="@mdo"
                dangerouslySetInnerHTML={{ __html: email.title[lang] }} />}
            <div className="modal fade" id="modal-3" tabIndex="-1"
              aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">{translatedTexts[lang].contacts.askQuestion}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal"
                      aria-label="Close" />
                  </div>
                  <div className="modal-body">
                    <form ref={formMessage} onSubmit={sendEmail}>
                      <div className="mb-3">
                        <input placeholder="ФИО" type="text" className="form-control"
                          id="recipient-name" />
                      </div>
                      <div className="mb-3">
                        <input placeholder="E-mail адрес" type="text" className="form-control"
                          id="recipient-name" />
                      </div>
                      <div className="mb-3">
                        <textarea placeholder="Сообщение" className="form-control"
                          id="message-text" />
                      </div>
                    </form>
                  </div>
                  <div className="modal-footer">
                    <button type="submit" className="btn btn-primary">{translatedTexts[lang].submit}</button>
                  </div>
                </div>
              </div>
            </div>
          </li>}
          <li className={styles.footer_twogis}>
            <div className={styles.twogis}><iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A48066fa764c0fbfdc47a0ddb4771de8d9b0a756630d7569846784d0870191771&amp;source=constructor" width="100%" height="536" frameborder="0"></iframe></div>          
          </li>
        </ul>
        <ul className={styles.footer_middle}>
          <li><Link className={styles.footer_middle_link}
            to="/about">{translatedTexts[lang].siteNavs.aboutCompany.title}</Link></li>
          <li><Link className={styles.footer_middle_link}
            to="/history">{translatedTexts[lang].siteNavs.history.title}</Link></li>
          <li><Link className={styles.footer_middle_link}
            to="/management">{translatedTexts[lang].siteNavs.management.title}</Link></li>
          <li><Link className={styles.footer_middle_link}
            to="/projects">{translatedTexts[lang].siteNavs.projects.title}</Link></li>
          <li><Link className={styles.footer_middle_link}
            to="/certificates">{translatedTexts[lang].siteNavs.certificates.title}</Link></li>
          <li><Link className={styles.footer_middle_link}
            to="/partners">{translatedTexts[lang].siteNavs.partners.title}</Link></li>
          <li><Link className={styles.footer_middle_link}
            to="/resources">{translatedTexts[lang].siteNavs.resources.title}</Link></li>
          <li><Link className={styles.footer_middle_link}
            to="/vacancies">{translatedTexts[lang].siteNavs.vacancies.title}</Link></li>
        </ul>
      </div> 
      <div className={styles.footer_bottom} id='footer_bottom'>
        <div className="container">
          <div className={styles.footer_bottom_inner}>
            <small className={styles.copyright}>
              {translatedTexts[lang].copyright}
            </small>
            <a href="https://instagram.com/kgcm_official?igshid=YmMyMTA2M2Y="><img src={instagram} /></a>
          </div>
        </div>
      </div>
    </CustomFooter>
  );
}
