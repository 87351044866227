import React, { useRef, useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import styles from '../styles/Header.module.css';
import letter from '../assets/icons/letter-icon.svg';
import facebook from '../assets/icons/facebook-icon.svg';
import instagram from '../assets/icons/instagram-icon.svg';
import phone from '../assets/icons/phone-icon.svg';
import { translatedTexts } from '../translatedTexts';
import *as axios from 'axios';
import arrow from '../assets/icons/arrow-down-icon.svg';
import logo from '../assets/icons/logo.svg';
import { changeLanguage, t } from 'i18next';
import { Trans, useTranslation } from 'react-i18next';
import Projects from '../pages/Projects';
// import {setLanguage} from './President';
import emailjs from '@emailjs/browser';

function Header({ toggleLanguage }) {
  const { t, i18n } = useTranslation();
  const [titles,setTitles]=useState()
  useEffect(()=>{
      axios.get('get/menu').then(res=>{
          setTitles(res.data)
      })
  },[])
  const checkbox = document.getElementById('checkbox');
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem('i18nextLng',language)
    window.location.reload();
  };
  const lang = localStorage.getItem('i18nextLng')
  const formMessage = useRef();
  const formPhone = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_6wz44i7', 'template_biuhaek', formMessage.current, '_Nt-SrtI2d_qLDnAn').then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
  };

  const sendPhone = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_6wz44i7', 'template_n9n0o1n', formPhone.current, '_Nt-SrtI2d_qLDnAn').then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
  };
  function check(event) {
    const menu = document.getElementById('menu_container');
    if (event.target.checked) {
      menu.style.height = '100%';
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
      menu.style.height = 0;
    } 
  }
  function hideMenu() {
    const menu = document.getElementById('menu_container');
    const checkbox = document.getElementById('checkbox');
    checkbox.checked = false;
    menu.style.height = 0;
    document.body.style.overflow = 'auto';
  }

  const links=['about','history','management','projects','certificates','partners','resources','vacancies']
  return (
    <header className={styles.header}>
      <div className={`container ${styles.header_inner}`}>
        <div className={styles.header_left}>
          <div className={styles.menu}>
            <input type='checkbox' id='checkbox' disabled={window.innerWidth >= 768 ? true : false} onClick={(e) => check(e)} className={styles.menu_checkbox} />
            <label htmlFor='checkbox' className={styles.menu_btn}>
              <div className={styles.menu_icon}></div>
            </label>
            <div className={styles.menu_container} id='menu_container'>
              <ul className={styles.menu_list}>
              {titles&&titles.data.map((slide, index)=>( 
                <li className={styles.menu_item} onClick={() => hideMenu()}>
                  <NavLink className={styles.menu_link} to={slide.link}>
                    {slide.title}
                  </NavLink>
                </li>
                )      
              )}
              </ul>
              <div className={styles.menu_bottom}>
                <ul className={styles.languages_list}>
                  <li className={styles.languages_item}>
                    <button className={styles.languages_btn} onClick={() => changeLanguage('ru')}>
                      Ru
                    </button>
                  </li>
                  <li className={styles.languages_item}>
                    <button className={styles.languages_btn} onClick={() => changeLanguage('kz')}>
                      Kz
                    </button>
                  </li>
                  <li className={styles.languages_item}>
                    <button className={styles.languages_btn} onClick={() => changeLanguage('en')}>
                      En
                    </button>
                  </li>
                </ul>
                {/* <a className={styles.call} href='tel:+7 (7232) 22-62-01'>
                  +7 (7232) 22-62-01
                </a> */}
                <ul className={styles.social_network_list}>
                  <li>
                    <button type='button' className={styles.header_right_button} data-bs-toggle='modal' data-bs-target='#modal-6' data-bs-whatever='@mdo'>
                      <img src={phone} alt='Позвонить' />
                    </button>
                    <div className='modal fade' id='modal-6' tabIndex='-1' aria-labelledby='exampleModalLabel' aria-hidden='true'>
                      <div className='modal-dialog  modal-dialog-centered'>
                        <div className='modal-content '>
                          <div className='modal-header'>
                            <h5 className='modal-title' id='exampleModalLabel'>
                              Оставьте ФИО и номер телефона. Наш специалист свяжется с вами
                            </h5>
                            <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
                          </div>
                          <form ref={formPhone} onSubmit={sendPhone}>
                            <div className='modal-body'>
                              <div className='mb-3'>
                                <input required minLength='3' name='from_name' placeholder='ФИО' type='text' className='form-control' id='recipient-name' />
                              </div>
                              <div className='mb-3'>
                                <textarea required minLength='3' name='phone' placeholder='Номер телефона' className='form-control' id='message-text'></textarea>
                              </div>
                            </div>
                            <div className='modal-footer'>
                              <button type='submit' className='btn btn-primary' data-bs-dismiss='modal'>
                                Отправить
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </li> 
                  <li>
                    <button type='button' className={styles.header_right_button} data-bs-toggle='modal' data-bs-target='#modal-5' data-bs-whatever='@mdo'>
                      <img src={letter} alt='Написать' />
                    </button> 
                    <div className='modal fade' id='modal-5' tabIndex='-1' aria-labelledby='exampleModalLabel' aria-hidden='true'>
                      <div className='modal-dialog modal-dialog-centered'>
                        <div className='modal-content'>
                          <div className='modal-header'>
                            <h5 className='modal-title' id='exampleModalLabel'>
                              Задайте свой вопрос
                            </h5>
                            <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
                          </div>
                          <form ref={formMessage} onSubmit={sendEmail}>
                            <div className='modal-body'>
                              <div className='mb-3'>
                                <input required minLength='3' name='from_name' placeholder='ФИО' type='text' className='form-control' id='recipient-name' />
                              </div>
                              <div className='mb-3'>
                                <input required minLength='3' name='reply_to' placeholder='E-mail адрес' type='text' className='form-control' id='recipient-name' />
                              </div>
                              <div className='mb-3'>
                                <textarea required minLength='3' maxLength='250' name='message' placeholder='Сообщение' className='form-control' id='message-text'></textarea>
                              </div>
                            </div>
                            <div className='modal-footer'>
                              <button type='submit' className='btn btn-primary' data-bs-dismiss='modal'>
                                Отправить
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <a href='https://instagram.com/kgcm_official?igshid=YmMyMTA2M2Y='>
                      <img src={instagram} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <NavLink className={styles.logo} to='/'>
            <img src={translatedTexts[lang].logotip} alt='Логотип' />
          </NavLink>
        </div>
        <div className={styles.header_right}>
          <ul className={styles.header_right_list}>
            <li className={styles.header_right_item}>
              <select
                value={
                  lang
                }
                className={styles.select}
                onChange={(e) => {
                  changeLanguage(e.target.value);
                }
                }>
                <option className={styles.option} value='ru'>
                  Ru
                </option>
                <option className={styles.option} value='en'>
                  En
                </option>
                <option className={styles.option} value='kz'>
                  Kz
                </option>
              </select>
              
            </li>
            <li className={styles.header_right_item}>
              <button type='button' className={styles.header_right_button} data-bs-toggle='modal' data-bs-target='#modal-2' data-bs-whatever='@mdo'>
                <img src={phone} alt='Позвонить' />
              </button>
              <div className='modal fade' id='modal-2' tabIndex='-1' aria-labelledby='exampleModalLabel' aria-hidden='true'>
                <div className='modal-dialog  modal-dialog-centered'>
                  <div className='modal-content '>
                    <div className='modal-header'>
                      <h5 className='modal-title' id='exampleModalLabel'>
                        Оставьте ФИО и номер телефона. Наш специалист свяжется с вами
                      </h5>
                      <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
                    </div>
                    <form ref={formPhone} onSubmit={sendPhone}>
                      <div className='modal-body'>
                        <div className='mb-3'>
                          <input required minLength='3' name='from_name' placeholder='ФИО' type='text' className='form-control' id='recipient-name' />
                        </div>
                        <div className='mb-3'>
                          <textarea required minLength='3' name='phone' placeholder='Номер телефона' className='form-control' id='message-text'></textarea>
                        </div>
                      </div>
                      <div className='modal-footer'>
                        <button type='submit' className='btn btn-primary' data-bs-dismiss='modal'>
                          Отправить
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </li>
            <li className={styles.header_right_item}>
              <button type='button' className={styles.header_right_button} data-bs-toggle='modal' data-bs-target='#modal-1' data-bs-whatever='@mdo'>
                <img src={letter} alt='Написать' />
              </button>
              <div className='modal fade' id='modal-1' tabIndex='-1' aria-labelledby='exampleModalLabel' aria-hidden='true'>
                <div className='modal-dialog modal-dialog-centered'>
                  <div className='modal-content'>
                    <div className='modal-header'>
                      <h5 className='modal-title' id='exampleModalLabel'>
                        Задайте свой вопрос
                      </h5>
                      <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
                    </div>
                    <form ref={formMessage} onSubmit={sendEmail}>
                      <div className='modal-body'>
                        <div className='mb-3'>
                          <input required minLength='3' name='from_name' placeholder='ФИО' type='text' className='form-control' id='recipient-name' />
                        </div>
                        <div className='mb-3'>
                          <input required minLength='3' name='reply_to' placeholder='E-mail адрес' type='text' className='form-control' id='recipient-name' />
                        </div>
                        <div className='mb-3'>
                          <textarea required minLength='3' maxLength='250' name='message' placeholder='Сообщение' className='form-control' id='message-text'></textarea>
                        </div>
                      </div>
                      <div className='modal-footer'>
                        <button type='submit' className='btn btn-primary' data-bs-dismiss='modal'>
                          Отправить
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </li>
            <li className={styles.header_right_item}>
              <a className={styles.header_right_link} href="https://instagram.com/kgcm_official?igshid=YmMyMTA2M2Y=">
                <img src={instagram} alt='Ссылка на инстаграм' />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}

export default Header;
