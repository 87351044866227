import VacanciesMain from '../components/VacanciesMain'
import Footer from '../components/Footer_2'
import React, { Component, useEffect, useState }  from 'react';
import hero from '../utils/hero'
import * as axios              from 'axios';
import Hero from '../components/Hero'
import { useTranslation } from 'react-i18next';
import CDN from '..';

export default function Vacancies(){
	const { t, i18n } = useTranslation();
	const [VacancyData,setData] = useState({});

 async function fetchData() {
		await axios
      .get('get/vacancy')
      .then((res) => {
        setData(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

	useEffect(() => {
		fetchData();
		window.scrollTo(0, 0)
	},[])
    return(
      <>
      {VacancyData.length !== 0 ?
          <>
            {VacancyData?.first && <Hero text={VacancyData.first.title} img={CDN + VacancyData.first.image} bottom={hero.vacancies.bottom}/>}
            <VacanciesMain vacancies={VacancyData}/> 
            <Footer/>
            </>: 
            <div/>}
        </> 
    )
}