import styles from '../styles/Industry.module.css';
import bottom_rectangle from '../assets/images/bottom-rectangle-2.svg'
import { useState, useEffect } from "react"
import *as axios from 'axios';
import { Link } from 'react-router-dom'

export default function Industry() {
    const [titles,setTitles]=useState()
    useEffect(()=>{
        axios.get('get/project-types').then(res=>{
            setTitles(res.data.data)
        })
    },[])
    const links=['mining-industry','metallurgy-and-enrichment-industry','chemical-industry','reviews']
    return (  
        <section className={styles.industry}>
            <div className='container'>
                <div className={styles.industry_inner}>
                {titles&&titles.map((slide, index)=>(
                    <Link className={styles.industry_link} to={`${links[index]}`}>
                        <span>{slide.title}</span>
                    </Link> 
                )      
                )}
                </div>
            </div>
            <img className={styles.bottom_rectangle} src={bottom_rectangle} alt={'rectangle'} />
        </section>
    );
}