import React, {useRef} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import {createRoot} from 'react-dom/client'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import styledComponents from 'styled-components';
import './i18n';
import axios from 'axios';

const lang = localStorage.getItem('i18nextLng') || 'ru';
const CDN = 'https://back-kgcm.kgcm.kz/'
axios.defaults.baseURL = 'https://back-kgcm.kgcm.kz/api/';
axios.defaults.params = {
    'lang': lang,
  };

const container = document.getElementById('root')
const root = createRoot(container)

root.render(<App/>)
// ReactDOM.render( 
//     <App />,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
  
export default CDN