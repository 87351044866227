import styles from '../styles/Sertificates_descr_2.module.css';
import Slider from './SlickSlider';
import bottom_rectangle from '../assets/images/bottom-rectangle.svg';

export default function Sertificates_descr_1({data}) {
  return (
    <section className={styles.sertificates_descr}>
      <div className={`container ${styles.sertificates_descr_inner}`}>
        <div className={styles.sertificates_descr_item}>
          {data?.title && <h2 className={styles.sertificates_descr_title} dangerouslySetInnerHTML={{__html: data.title}} />}
          {/* {data?.images && <Slider images={data.images}/>} */}
          {data?.description && <p className={styles.sertificates_descr_paragraph} dangerouslySetInnerHTML={{__html: data.description}} />}
        </div>
      </div>
      <img className={styles.bottom_rectangle} src={bottom_rectangle} alt={'img'} />
    </section>
  );
}
