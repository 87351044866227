import management from '../assets/images/management-hero-img.png'
import about_company from '../assets/images/about-company-hero-img.png'
import history from '../assets/images/history-hero-img.png'
import projects from '../assets/images/projects-hero-img.png'
import certificates from '../assets/images/certificates-hero-img.png'
import partners from '../assets/images/partners-hero-img.png'
import resources from '../assets/images/resources-hero-img.png'
import vacancies from '../assets/images/vacancies-hero-img.png'
import hero_bottom_2 from '../assets/images/hero-bottom-2.svg'
import hero_bottom from '../assets/images/hero-bottom.svg'

let hero = {
  general_engineering:{
    text:'Отзывы',
    img:projects, 
    bottom:hero_bottom
  },
  chemical_industry:{
    text:' Химическая промышленность',
    img:projects,
    bottom:hero_bottom
  },
  metallurgy_and_enrichment:{
    text:' Металлургия и обогащение',
    img:projects,
    bottom:hero_bottom
  },
  mining_industry:{
    text:'Горнодобывающая промышленность',
    img:projects,
    bottom:hero_bottom
  },
  mining:{
    text:"Горный",
    img:management,
    bottom:hero_bottom_2
  } ,
  metallurgy:{
    text:"Отдел обогащения, металлургии и экологии",
    img:management,
    bottom:hero_bottom_2
  } ,
  architecture:{
    text:"Архитектурно-строительный отдел",
    img:management,
    bottom:hero_bottom_2
  } ,
  transport:{
    text:"Отдел генплана и транспорта",
    img:management,
    bottom:hero_bottom_2
  } ,
  electrotechnical:{
    text:"Электротехнический отдел",
    img:management,
    bottom:hero_bottom_2
  } ,
  plumbing:{ 
    text:"Сантехнический отдел",
    img:management, 
    bottom:hero_bottom_2
  } ,
  economic:{
    text:"Технико-экономический отдел",
    img:management,
    bottom:hero_bottom_2
  },
  automation:{
    text:"Отдел автоматизации проектных работ",
    img:management,
    bottom:hero_bottom_2
  },
  about_company:{
    text:"О компании",
    img:about_company,
    bottom:hero_bottom_2
  },
  history:{
    text:"История",
    img:history,
    bottom:hero_bottom
  },
  management:{
    text:"Руководители",
    img:management,
    bottom:hero_bottom_2
  },
  projects:{
    text:"Проекты",
    img:projects,
    bottom:hero_bottom
  },
  certificates:{
    text:"Сертификаты и Политика качества",
    img:certificates,
    bottom:hero_bottom
  },
  partners:{
    text:"Партнеры",
    img:partners,
    bottom:hero_bottom_2
  },
  resources:{
    text:"Новости",
    img:resources,
    bottom:hero_bottom
  },
  vacancies:{
    text:"Вакансии",
    img:vacancies,
    bottom:hero_bottom
  } 
};

export default hero;