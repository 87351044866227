import styles from '../styles/Map.module.css'
import map from '../assets/images/map.svg'
import bottom_rectangle from '../assets/images/bottom-rectangle.svg'
import red_circle from '../assets/icons/red-circle.svg'
import yellow_circle from '../assets/icons/yellow-circle.svg'
import green_circle from '../assets/icons/green-circle.svg'
import line from '../assets/images/line.svg'
import map_descr from '../assets/images/map-descr.svg'
import {translatedTexts} from '../translatedTexts';
import {useTranslation} from 'react-i18next';


export default function Map({data, lang, top_rectangle }) {
    const {t, i18n: {language}} = useTranslation();
    let i;
    const onHover = (e) => {
        let lineId = e.target.id - 1;
        let lines = document.getElementsByClassName("line");
        for (i = 0; i < 30; i++) {
            if (lineId === i) {
                lines[i].style.display = "block"
                window.flag = true;
            }
            else {
                lines[i].style.display = "none"
            }
        }
    }
    return (
        <section className={styles.map_block}>
            <img className={styles.top_rectangle} src={top_rectangle} />
            <img className={styles.bottom_rectangle} src={bottom_rectangle} />
            <div className={styles.map}>
                <span className={styles.object_map}  dangerouslySetInnerHTML={{ __html: data.third[0].title }} />
                <img className={styles.map_img} src={map} />
                <div className={styles.map_descr}>
                    <p className={`${styles.map_descr_text} ${styles.map_descr_text_1}`}>{translatedTexts[language].maps.legends.one}</p>
                    <p className={`${styles.map_descr_text} ${styles.map_descr_text_2}`}>{translatedTexts[language].maps.legends.two}</p>
                    <p className={`${styles.map_descr_text} ${styles.map_descr_text_3}`}>{translatedTexts[language].maps.legends.three}</p>
                </div>
                <img id="1" className={`${styles.circle} ${styles.circle_1}`} onClick={e => onHover(e)} src={yellow_circle} />
                <img id="2" className={`${styles.circle} ${styles.circle_2}`} onClick={e => onHover(e)} src={yellow_circle} />
                <img id="3" className={`${styles.circle} ${styles.circle_3}`} onClick={e => onHover(e)} src={green_circle} />
                <img id="4" className={`${styles.circle} ${styles.circle_4}`} onClick={e => onHover(e)} src={green_circle} />
                <img id="5" className={`${styles.circle} ${styles.circle_5}`} onClick={e => onHover(e)} src={green_circle} />
                <img id="6" className={`${styles.circle} ${styles.circle_6}`} onClick={e => onHover(e)} src={green_circle} />
                <img id="7" className={`${styles.circle} ${styles.circle_7}`} onClick={e => onHover(e)} src={green_circle} />
                <img id="8" className={`${styles.circle} ${styles.circle_8}`} onClick={e => onHover(e)} src={green_circle} />
                <img id="9" className={`${styles.circle} ${styles.circle_9}`} onClick={e => onHover(e)} src={green_circle} />
                <img id="10" className={`${styles.circle} ${styles.circle_10}`} onClick={e => onHover(e)} src={green_circle} />
                <img id="11" className={`${styles.circle} ${styles.circle_11}`} onClick={e => onHover(e)} src={green_circle} />
                <img id="12" className={`${styles.circle} ${styles.circle_12}`} onClick={e => onHover(e)} src={red_circle} />
                {/* <img id="13" className={`${styles.circle} ${styles.circle_13}`} onClick={e => onHover(e)} src={red_circle} /> */}
                <img id="14" className={`${styles.circle} ${styles.circle_14}`} onClick={e => onHover(e)} src={green_circle} />
                <img id="15" className={`${styles.circle} ${styles.circle_15}`} onClick={e => onHover(e)} src={red_circle} />
                <img id="16" className={`${styles.circle} ${styles.circle_16}`} onClick={e => onHover(e)} src={red_circle} />
                <img id="17" className={`${styles.circle} ${styles.circle_17}`} onClick={e => onHover(e)} src={green_circle} />
                <img id="18" className={`${styles.circle} ${styles.circle_18}`} onClick={e => onHover(e)} src={green_circle} />
                <img id="19" className={`${styles.circle} ${styles.circle_19}`} onClick={e => onHover(e)} src={green_circle} />
                <img id="20" className={`${styles.circle} ${styles.circle_20}`} onClick={e => onHover(e)} src={green_circle} />
                <img id="21" className={`${styles.circle} ${styles.circle_21}`} onClick={e => onHover(e)} src={green_circle} />
                <img id="22" className={`${styles.circle} ${styles.circle_22}`} onClick={e => onHover(e)} src={green_circle} />
                <img id="23" className={`${styles.circle} ${styles.circle_23}`} onClick={e => onHover(e)} src={green_circle} />
                <img id="24" className={`${styles.circle} ${styles.circle_24}`} onClick={e => onHover(e)} src={green_circle} />
                <img id="25" className={`${styles.circle} ${styles.circle_25}`} onClick={e => onHover(e)} src={green_circle} />
                <img id="26" className={`${styles.circle} ${styles.circle_26}`} onClick={e => onHover(e)} src={green_circle} />
                <img id="27" className={`${styles.circle} ${styles.circle_27}`} onClick={e => onHover(e)} src={green_circle} />
                <img id="28" className={`${styles.circle} ${styles.circle_28}`} onClick={e => onHover(e)} src={green_circle} />
                <img id="29" className={`${styles.circle} ${styles.circle_29}`} onClick={e => onHover(e)} src={green_circle} />
                <img id="30" className={`${styles.circle} ${styles.circle_30}`} onClick={e => onHover(e)} src={green_circle} />
                <div id="1" className={`line ${styles.line_1}`}>
                    <span className={styles.object_name}  dangerouslySetInnerHTML={{ __html: translatedTexts[language].maps.points.p1}} />
                    <img src={line} />
                </div>
                <div id="2" className={`line ${styles.line_2}`}>
                    <span className={styles.object_name}  dangerouslySetInnerHTML={{ __html: translatedTexts[language].maps.points.p2}} />
                    <img src={line} />
                </div>
                <div id="3" className={`line ${styles.line_3}`}>
                    <span className={styles.object_name}  dangerouslySetInnerHTML={{ __html: translatedTexts[language].maps.points.p3}} />
                    <img src={line} />
                </div>
                <div id="4" className={`line ${styles.line_4}`}>
                    <span className={styles.object_name}  dangerouslySetInnerHTML={{ __html: translatedTexts[language].maps.points.p4}} />
                    <img src={line} />
                </div>
                <div id="5" className={`line ${styles.line_5}`}>
                    <span className={styles.object_name}  dangerouslySetInnerHTML={{ __html: translatedTexts[language].maps.points.p5}} />
                    <img src={line} />
                </div>
                <div id="6" className={`line ${styles.line_6}`}>
                    <span className={styles.object_name}  dangerouslySetInnerHTML={{ __html: translatedTexts[language].maps.points.p6}} />
                    <img src={line} />
                </div>
                <div id="7" className={`line ${styles.line_7}`}>
                    <span className={styles.object_name}  dangerouslySetInnerHTML={{ __html: translatedTexts[language].maps.points.p7}} />
                    <img src={line} />
                </div>
                <div id="8" className={`line ${styles.line_8}`}>
                    <span className={styles.object_name}  dangerouslySetInnerHTML={{ __html: translatedTexts[language].maps.points.p8}} />
                    <img src={line} />
                </div>
                <div id="9" className={`line ${styles.line_9}`}>
                    <span className={styles.object_name}  dangerouslySetInnerHTML={{ __html: translatedTexts[language].maps.points.p9}} />
                    <img src={line} />
                </div>
                <div id="10" className={`line ${styles.line_10}`}>
                    <span className={styles.object_name}  dangerouslySetInnerHTML={{ __html: translatedTexts[language].maps.points.p10}} />
                    <img src={line} />
                </div>
                <div id="11" className={`line ${styles.line_11}`}>
                    <span className={styles.object_name}  dangerouslySetInnerHTML={{ __html: translatedTexts[language].maps.points.p11}} />
                    <img src={line} />
                </div>
                <div id="12" className={`line ${styles.line_12}`}>
                    <span className={styles.object_name}  dangerouslySetInnerHTML={{ __html: translatedTexts[language].maps.points.p12}} />
                    <img src={line} />
                </div>
                {/* <div id="13" className={`line ${styles.line_13}`}>
                    <span className={styles.object_name}  dangerouslySetInnerHTML={{ __html: translatedTexts[language].maps.points.p13}}>ПАО ГМК «Норильский Никель»<br />Комплекс непрерывного конвертирования медного завода</span>
                    <img src={line} />
                </div> */}
                <div id="14" className={`line ${styles.line_14}`}>
                    <span className={styles.object_name}  dangerouslySetInnerHTML={{ __html: translatedTexts[language].maps.points.p14}} />
                    <img src={line} />
                </div>
                <div id="15" className={`line ${styles.line_15}`}>
                    <span className={styles.object_name}  dangerouslySetInnerHTML={{ __html: translatedTexts[language].maps.points.p15}} />
                    <img src={line} />
                </div>
                <div id="16" className={`line ${styles.line_16}`}>
                    <span className={styles.object_name}  dangerouslySetInnerHTML={{ __html: translatedTexts[language].maps.points.p16}} />
                    <img src={line} />
                </div>
                <div id="17" className={`line ${styles.line_17}`}>
                    <span className={styles.object_name}  dangerouslySetInnerHTML={{ __html: translatedTexts[language].maps.points.p17}} />
                    <img src={line} />
                </div>
                <div id="18" className={`line ${styles.line_18}`}>
                    <span className={styles.object_name}  dangerouslySetInnerHTML={{ __html: translatedTexts[language].maps.points.p18}} />
                    <img src={line} />
                </div> <div id="19" className={`line ${styles.line_19}`}>
                    <span className={styles.object_name}  dangerouslySetInnerHTML={{ __html: translatedTexts[language].maps.points.p19}} />
                    <img src={line} />
                </div> <div id="20" className={`line ${styles.line_20}`}>
                    <span className={styles.object_name}  dangerouslySetInnerHTML={{ __html: translatedTexts[language].maps.points.p20}} />
                    <img src={line} />
                </div> <div id="21" className={`line ${styles.line_21}`}>
                    <span className={styles.object_name}  dangerouslySetInnerHTML={{ __html: translatedTexts[language].maps.points.p21}} />
                    <img src={line} />
                </div> <div id="22" className={`line ${styles.line_22}`}>
                    <span className={styles.object_name}  dangerouslySetInnerHTML={{ __html: translatedTexts[language].maps.points.p22}} />
                    <img src={line} />
                </div> <div id="23" className={`line ${styles.line_23}`}>
                    <span className={styles.object_name}  dangerouslySetInnerHTML={{ __html: translatedTexts[language].maps.points.p23}} />
                    <img src={line} />
                </div> <div id="24" className={`line ${styles.line_24}`}>
                    <span className={styles.object_name}  dangerouslySetInnerHTML={{ __html: translatedTexts[language].maps.points.p24}} />
                    <img src={line} />
                </div> <div id="25" className={`line ${styles.line_25}`}>
                    <span className={styles.object_name}  dangerouslySetInnerHTML={{ __html: translatedTexts[language].maps.points.p25}} />
                    <img src={line} />
                </div> <div id="26" className={`line ${styles.line_26}`}>
                    <span className={styles.object_name}  dangerouslySetInnerHTML={{ __html: translatedTexts[language].maps.points.p26}} />
                    <img src={line} />
                </div> <div id="27" className={`line ${styles.line_27}`}>
                    <span className={styles.object_name}  dangerouslySetInnerHTML={{ __html: translatedTexts[language].maps.points.p27}} />
                    <img src={line} />
                </div> <div id="28" className={`line ${styles.line_28}`}>
                    <span className={styles.object_name}  dangerouslySetInnerHTML={{ __html: translatedTexts[language].maps.points.p28}} />
                    <img src={line} />
                </div> <div id="29" className={`line ${styles.line_29}`}>
                    <span className={styles.object_name}  dangerouslySetInnerHTML={{ __html: translatedTexts[language].maps.points.p29}} />
                    <img src={line} />
                </div> <div id="30" className={`line ${styles.line_30}`}>
                    <span className={styles.object_name}  dangerouslySetInnerHTML={{ __html: translatedTexts[language].maps.points.p30}} />
                    <img src={line} />
                </div>
            </div>
        </section>
    )
}
