import React, {Component} from 'react';
import Slider             from 'react-slick';
import '../styles/VerticalSlickSlider.css';
import styled from 'styled-components';
import {translatedTexts} from '../translatedTexts';

// const tempImages = [license_1, license_2, license_3, license_4, license_5, {src: certificate_3}];
export default class VerticalSwipeToSlide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageToShow: '',
      isOpen:      false,
      baseUrl:     'https://back-kgcm.kgcm.kz/',
    };
    this.props = props;
  } 

  showImage = (image) => {
    //set imageToShow to be the one that's been clicked on
    this.setState({imageToShow: image});
    //set lightbox visibility to true
    this.setState({isOpen: true});
  };

  hideLightBox = () => {
    this.setState({isOpen: false});
  };

  showNext = (e) => {
    e.stopPropagation();
    let currentIndex = this.props.urls.indexOf(this.state.imageToShow);
    if (currentIndex >= this.props.urls.length - 1) {
      this.setState({isOpen: false});
    } else {
      let nextImage = this.props.urls[currentIndex + 1];
      this.setState({imageToShow: nextImage});
    }
  };

  showPrev = (e) => {
    e.stopPropagation();
    let currentIndex = this.props.urls.indexOf(this.state.imageToShow);
    if (currentIndex <= 0) {
      this.setState({isOpen: false});
    } else {
      let nextImage = this.props.urls[currentIndex - 1];
      this.setState({imageToShow: nextImage});
    }
  };

  render() {
    console.log('VerticalSwipeToSlide URLS', this.props.urls);
    const {isOpen, imageToShow, baseUrl} = this.state;
    const {showImage, showPrev, showNext, hideLightBox} = this;
    const {urls} = this.props;
    const CustomSlider = styled(Slider)`
      .slick-slide {
        max-width: 146px;
        max-height: 208px;
      }

      .slick-slide img {
        width: 100%;
        height: 100%;
      }


      .slick-track {
        height: auto !important;
      }
      .slick-dots {
        transform: rotate(90deg);
        display: flex !important;
        justify-content: center;
        width: 471px !important;
        left: -32px !important;
        bottom: 300px !important;
      }

      @media (max-width: 767px) {
        .slick-slider {
          margin: 0 auto;
        }

        .slick-track {
          display: flex;
        }

        .slick-dots {
          position: initial;
          height: initial;
          width: initial !important;
          transform: initial !important;
          margin: 0 auto;
        }

        .slick-slide {
          margin: 0 5px;
        }
      }
    `;
    const settings = {
      dots:            true,
      infinite:        true,
      slidesToShow:    3,
      slidesToScroll:  1,
      vertical:        true,
      verticalSwiping: true,
      swipeToSlide:    true,
      responsive:      [
        {
          breakpoint: 768,
          settings:   {
            verticalSwiping: false,
            vertical:        false,
          },
        },
      ],
    };

    return ( 
      <>
        <CustomSlider {...settings} className="vertical_slider">
          {urls.map((image, index) => (
            <div key={index} onClick={() => showImage(image)}>
              <img src={baseUrl + image} alt="certificate" />
            </div>
          ))}
        </CustomSlider>
        {isOpen && (
          <div id="lightbox" onClick={() => hideLightBox()}>
            <button className="prev" onClick={(e) => showPrev(e)}>
              {translatedTexts[this.props.lang].prev}
            </button>
            <img id="lightbox-img" src={baseUrl + imageToShow} alt="certificate" />
            <button className="next" onClick={(e) => showNext(e)}>
              {translatedTexts[this.props.lang].next}
            </button>
          </div>
        )}
      </>
    );
  }
}
