import React from "react";
import styles from '../styles/OurPartners.module.css'
import line from '../assets/images/partners-line.svg'
import top_rectangle from '../assets/images/top-rectangle-3.svg'
import bottom_rectangle from '../assets/images/bottom-rectangle-2.svg'
import CDN from "..";

export default function OurPartners({ data }) {
    return (
        <section className={styles.our_partners}>
            <img className={styles.top_rectangle} src={top_rectangle} />
            <div className="container">
                <div className={styles.our_partners_inner}>
                    {data.map((img, index)=>(
                        <img key={index} src={CDN+img.icon} className={styles.our_partners_img}/>
                    ))}
                </div>
            </div>
            <img className={styles.bottom_rectangle} src={bottom_rectangle}></img>
        </section>
    )
}
