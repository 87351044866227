import React from 'react';
import styles from '../styles/HistoricalDates.module.css';
import top_rectangle from '../assets/images/top-rectangle.svg';
import bottom_rectangle from '../assets/images/bottom-rectangle.svg';

export default function HistoricalDates({data}) {
  return (
    <section className={styles.historical_dates}>
      <img className={styles.top_rectangle} src={top_rectangle} alt={"img"}/>
      <div className={`container ${styles.historical_dates_inner}`}>
        <h1 className={styles.historical_dates_title} dangerouslySetInnerHTML={{__html:data.title}}/>
        {data.descriptions.map((descrition, index) => (
          <p key={index} className={styles.historical_dates_paragraph} dangerouslySetInnerHTML={{__html: descrition}}/>
        ))}
      </div>
      <img className={styles.bottom_rectangle} src={bottom_rectangle} alt={'history'}/>
    </section>
  ); 
}
