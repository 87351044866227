import { Link} from 'react-router-dom'
import styles from '../styles/NavCards.module.css'
import history_icon from '../assets/icons/history-icon.svg'
import about_company_icon from '../assets/icons/about-company-icon.svg'
import projects_icon from '../assets/icons/projects-icon.svg'
import partners_icon from '../assets/icons/partners-icon.svg'
import management_icon from '../assets/icons/management-icon.svg'
import resources_icon from '../assets/icons/resources-icon.svg'
import sertificates_icon from '../assets/icons/sertificates-icon.svg'
import career_icon from '../assets/icons/career-icon.svg'
import {translatedTexts} from '../translatedTexts';
import CDN from '..'
 
export default function SiteNav({data}) {
    return (  
        <section className={styles.cards}>
            <div className="container">
                <div className={styles.cards_inner}>
                    {data.map((item, index) => (
                        <Link className={styles.cards_link} to={item.link}>
                            <span dangerouslySetInnerHTML={{ __html: item.title }}></span>
                            <div className={styles.history}><img src={CDN + item.icon} alt="горы" /></div>
                        </Link>
                    ))}
                </div>
            </div>
        </section>
    )
}
 