import styles from '../styles/AboutCompanyMain.module.css';
import Slider from './SlickSlider';
import React from 'react';
import presentation from '../assets/presentation.pdf'
import bottom_rectangle from '../assets/images/bottom-rectangle-2.svg';
import { useTranslation } from 'react-i18next';
import { translatedTexts } from '../translatedTexts';

 
export default function AboutCompanyMain({ data }) {
  const {t, i18n: {language}} = useTranslation();
  return (
    <section className={styles.about_company}>
      <div className={`container ${styles.about_company_inner}`}>
        <h2 className={styles.about_company_title} dangerouslySetInnerHTML={{ __html: data.title }} />
        <div className={styles.about_company_text}>
          {data?.descriptions && data.descriptions.map((desc, index) => (
            <p key={index} className={styles.about_company_descr} dangerouslySetInnerHTML={{ __html: desc }} />
          ))}
        </div>
        {data?.images && <Slider images={data?.images} />}
        <a className={styles.presentation} href={presentation} download="">{translatedTexts[language].presentation}</a>
      </div>
      <img className={styles.bottom_rectangle} src={bottom_rectangle}></img>
    </section>
  ); 
}
 