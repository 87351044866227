import React        from 'react';
import styles       from '../styles/PartnersDescr.module.css';
import partners_img from '../assets/images/partners-img.png';
import ShowMore     from './ShowMore';

export default function PartnersDescr({data}) {
  return (
    <section className={styles.partners_descr}>
      <div className={`container ${styles.partners_descr_inner}`}>
        <div className={styles.partners_descr_text}>
          <p className={styles.partners_descr_paragraph} dangerouslySetInnerHTML={{__html: data.title}} />
          <p style={{marginBottom: 0}} dangerouslySetInnerHTML={{__html: data.description}} />
        </div>
        {/* <div className={`col-lg-5 col-md-5 ${styles.partners_descr_img}`}>
          <img src={partners_img} alt={'partners'} />
        </div> */}
      </div>
    </section> 
  );
}
