import React from "react";
import Slider from "react-slick";
import styled from 'styled-components';

export default function SlickSlider({images}) {
  const CustomSlider = styled(Slider)`
    // margin:0 auto;
    // .slick-track{
    //     display:flex !important;
    //     justify-content:space-between;
    // }
    .slide {
    }
    .slick-slide img {
      object-fit: cover;
      width: 100%;
      height: 200px;
    }
    .slick-slide {
      padding: 0 5px;
    }

    .slick-track {
      display: flex !important;
    }
  `
  const baseUrl = 'https://back-kgcm.kgcm.kz/';
  const settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 2500,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  }
  if (!images) return <></>
  return (
    <CustomSlider {...settings}>
      {images.map((img, index) => (
        <div style={`heigth: 100%`} key={index}>
          <img src={baseUrl + img.path} key={index} alt='slider'/>
        </div>
      ))}
    </CustomSlider>
  );
}
