import AboutCompanyMain from '../components/AboutCompanyMain'
import ProductionDepartments from '../components/ProductionDepartments'
import Footer from '../components/Footer_2'
import React, { useState, useEffect } from "react"
import *as axios from 'axios';
import hero from '../utils/hero'
import Hero from '../components/Hero'
import { useTranslation } from 'react-i18next';
import CDN from '..';

export default function AboutCompany(){
    const [AboutCompanyData, setAboutCompanyData] = useState([]);
	const { t, i18n } = useTranslation();
    useEffect(() => {
        async function fetchData() {
            await axios.get('get/activity').then(res => {
                setAboutCompanyData(res.data)
            }).catch(e => {
                console.log(e)
            })
        }
        fetchData(); 
        window.scrollTo(0, 0)
    }, [])
    return(
        <>
        {AboutCompanyData.length !== 0 ?
            <>
                <Hero text={AboutCompanyData.first.title} img={CDN + AboutCompanyData.first.image} bottom={hero.about_company.bottom} /> 
                <ProductionDepartments /> 
                <AboutCompanyMain data={AboutCompanyData.data} lang={i18n.language}/>
                <Footer/> 
            </>: 
            <div/>}
        </> 
    )
}