import SLider from './SlickSlider';
import styles from '../styles/Department.module.css';
import bottom_rectangle from '../assets/images/bottom-rectangle.svg';
import ShowMore from './ShowMore';

export default function MiningDepartmentMain({data}) {
  return (
    <section className={styles.department}>
      <div className='container'>
        <div className={styles.department_inner}>
          {data?.data && data.data.map((item) => (
            <div key={item.id}>
              <h2 className={styles.department_title} dangerouslySetInnerHTML={{ __html: item.title }} />
              <p className={styles.department_descr} dangerouslySetInnerHTML={{ __html: item.description }} />
            </div>
          ))}
        </div>
        <div className={styles.slider}>
          <SLider images={data?.images} />
        </div>
      </div>
      <img className={styles.bottom_rectangle} src={bottom_rectangle} alt='img' />
    </section>
  );
}
