import President               from '../components/President';
import HistoricalDates         from '../components/HistoricalDates';
import HistoryDescr_1          from '../components/HistoryDescr_1';
import HistoryDescr_2          from '../components/HistoryDescr_2';
import Footer                  from '../components/Footer_2';
import {useState, useEffect}   from 'react';
import * as axios              from 'axios';
import React, {Component}      from 'react';
import {Trans, useTranslation} from 'react-i18next';
import hero                    from '../utils/hero';
import Hero                    from '../components/Hero';
import history                 from '../assets/images/history-hero-img.png';
import Slider                  from '../components/Slider';
import CDN from '..';

export default function History() {
  const {t, i18n} = useTranslation();
  const [historyData, setHistoryData] = useState([]);
  const [language, setLanguage] = useState();

  async function fetchData() {
    await axios
      .get('get/history')
      .then((res) => {
        setHistoryData(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  useEffect(() => {
    setLanguage(i18n.language);
    fetchData();
    window.scrollTo(0, 0)
  }, [i18n.language]);
  console.log('historyData', historyData);
  return (
    <>
      {historyData.length !== 0 ? (
        <div>
          <Hero text={historyData.first.title} img={CDN + historyData.first.image} bottom={hero.history.bottom} />
          <President data={historyData.data[0]} />
          <HistoricalDates data={historyData.data[1]} lang={language} />
          <HistoryDescr_1 data={historyData} lang={language} />
          {/* <HistoryDescr_2 data={historyData[8]} lang={language} /> */}
        </div>
      ) : ( 
        <div></div>
      )}
      <Footer />
    </>
  );
}
