import React from "react";
import styles from '../styles/Hero.module.css'
import hero_left from '../assets/images/hero-left.png'
import hero_lines from '../assets/images/hero-lines.svg'
import blue_line from '../assets/images/blue-line.png'

export default function Hero({text,img,bottom}){
    return(                 
        <section className={styles.hero}>
            <img className={styles.hero_bottom} src={bottom} />
            <img className={styles.hero_img} src={img}/>
            <img className={styles.hero_lines} src={hero_lines} />
            <img className={styles.hero_left} src={hero_left} />  
            <div className={styles.hero_text_container}><p className={styles.hero_text}>{text}</p></div>
            <img src={blue_line} className={styles.blue_line}/>
        </section>
    )
} 