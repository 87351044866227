import React from 'react';
import styles from '../styles/CompanyManagement.module.css';
import bottom_rectangle             from '../assets/images/bottom-rectangle-2.svg';
import grey_block from '../assets/images/grey-block.png';
import CDN from '..';
import {translatedTexts} from '../translatedTexts';
import {useTranslation} from 'react-i18next';

export default function CompanyManagement({ stuff }) {
  const {t, i18n: {language}} = useTranslation();
  return (
    <section className={styles.management}>
      <div className={`container ${styles.container}`}>
        {stuff?.data && (
          <div className={styles.management_directors_item}  style={{margin:'0 auto'}}>
            <img src={CDN + stuff.data[0].people[0].image} />
            <p className={styles.management_name} dangerouslySetInnerHTML={{ __html: stuff.data[0].people[0].title }} />
            <p className={styles.management_post} dangerouslySetInnerHTML={{ __html: stuff.data[0].people[0].description }} />
          </div>
        )}
        <ul className={styles.management_directors_list}>
          {stuff?.data &&
            stuff.data[0].people.map((person, index) => {
              if (person.type === 'gen_director') return null;
              return (
                <li key={index} className={styles.management_directors_item}>
                  <img src={CDN + person.image} alt={'person'} />
                  <p className={styles.management_name} dangerouslySetInnerHTML={{ __html: person.title }} />
                  <p className={styles.management_post} dangerouslySetInnerHTML={{ __html: person.description }} />
                </li>
              );
            })}
        </ul>
        <h2 className={styles.management_subtitle}>
              {translatedTexts[language].mainEngeneer}</h2>
        <ul className={styles.management_directors_list}>
          {stuff?.data &&
            stuff.data[1]?.people.map((person, index) => {
              if (person.type === 'gen_director') return null;
              return (
                <li key={index} className={styles.management_directors_item}>
                  <img src={CDN + person.image} alt={'person'} />
                  <p className={styles.management_name} dangerouslySetInnerHTML={{ __html: person.title }} />
                  <p className={styles.management_post} dangerouslySetInnerHTML={{ __html: person.description }} />
                </li>
              );
            })}
        </ul>
      </div>
      <img className={styles.bottom_rectangle} src={bottom_rectangle} alt='img' />
    </section>
  );
}
