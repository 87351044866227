import React from "react";
import styles from '../styles/VacanciesMain.module.css'
import Slider from './SlickSlider'
import bottom_rectangle from '../assets/images/bottom-rectangle-2.svg'

export default function VacanciesMain({vacancies}) {
  return (
    <section className={styles.vacancies}>
      <div className="container">
        <div className={styles.vacancies_inner}>
          <div className={styles.vacancies_jobs}>
            {vacancies?.data && vacancies.data.map((vacancy, index) => (
              <div key={index}>
                <h3 className={styles.vacancies_department} dangerouslySetInnerHTML={{__html: vacancy.title}}/>
                <p className={styles.vacancies_jobs_descr}
                   dangerouslySetInnerHTML={{__html: vacancy.descriptions}}/>
              </div>
            ))}
          </div>
          {vacancies?.images && <Slider images={vacancies?.images}/>}
        </div>
      </div>
      <img className={styles.bottom_rectangle} src={bottom_rectangle} alt={'rectangle'}/>
    </section>
  )
}


