import ManagementSystem from '../components/ManagementSystem';
import Sertificates_descr_1 from '../components/Sertificates_descr_1';
import Sertificates_descr_2 from '../components/Sertificates_descr_2';
import Footer from '../components/Footer_1';
import React, {Component, useEffect, useState} from 'react';
import hero from '../utils/hero';
import Hero from '../components/Hero';
import {useTranslation} from 'react-i18next';
import axios from 'axios';
import CDN from '..';

export default function Certificates() {
  const {t, i18n} = useTranslation();
  const {language} = i18n;
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
        await axios.get('get/certificate').then(res => {
          setData(res.data)
        }).catch(e => {
            console.log(e)
        })
    }
    fetchData(); 
    window.scrollTo(0, 0)
  }, [])
  console.log('Certificates ', data);
  return (
    <>
    {data.length !== 0 ?
        <>
      <Hero text={data.first.title} img={CDN + data.first.image} bottom={hero.certificates.bottom} />
      <ManagementSystem data={data.data[0]} lang={language} />
      {/* TODO Imported JSX component Sertificates_descr_1 must be in PascalCase or SCREAMING_SNAKE_CASE */}
      <Sertificates_descr_1 data={data.data.slice(2)} lang={language} />
      <Sertificates_descr_2 data={data.data[1]} lang={language} />
      <Footer />
            </>: 
            <div/>}
        </> 
    )
}
