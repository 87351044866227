import './App.css';
import Header from './components/Header'
import Main from './pages/Main'
import History from './pages/History'
import Sertificates from './pages/Sertificates'
import Partners from './pages/Partners'
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Projects from './pages/Projects'
import Management from './pages/Management'
import AboutCompany from './pages/AboutCompany'
import Resources from './pages/Resources'
import Vacancies from './pages/Vacancies'
import React, { Component } from 'react';
import Mining from './pages/Mining'
import Metallurgy from './pages/Metallurgy'
import Architecture from './pages/Architecture'
import Transport from './pages/Transport'
import Electrotechnical from './pages/Electrotechnical'
import Plumbing from './pages/Plumbing'
import Economic from './pages/Economic'
import Automation from './pages/Automation'
import MiningIndustry from './pages/MiningIndustry'
import MetallurgyAndEnrichment from './pages/MetallurgyAndEnrichment'
import ChemicalIndustry from './pages/ChemicalIndustry'
import Reviews from './pages/Reviews'


function App() {
  return (
    <BrowserRouter>
        <Header />
        <Switch> 
          <Route exact path='/' component={Main} />
          <Route path='/history' component={History} /> 
          <Route path='/certificates' component={Sertificates} />
          <Route path='/partners' component={Partners} />
          <Route path='/projects' component={Projects} />
          <Route path='/management' component={Management} />
          <Route path='/about' component={AboutCompany} />
          <Route path='/resources' component={Resources} />
          <Route path='/vacancies' component={Vacancies} />
          <Route path='/mining-department' component={Mining} /> 
          <Route path='/metallurgy' component={Metallurgy} />
          <Route path='/architecture' component={Architecture} />
          <Route path='/transport' component={Transport} />
          <Route path='/electrotechnical' component={Electrotechnical} />
          <Route path='/plumbing' component={Plumbing} />
          <Route path='/economic' component={Economic} />
          <Route path='/automation' component={Automation} />
          <Route path='/mining-industry' component={MiningIndustry} />
          <Route path='/metallurgy-and-enrichment-industry' component={MetallurgyAndEnrichment} />
          <Route path='/chemical-industry' component={ChemicalIndustry} />
          <Route path='/reviews' component={Reviews} />
        </Switch>
    </BrowserRouter>
  );
}

export default App;


