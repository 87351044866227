import React, {useEffect, useState} from 'react';
import styles                       from '../styles/Sertificates_descr_1.module.css';
import VerticalSlickSlider          from './VerticalSlickSlider';
import SlickSlider                  from './SlickSlider';
import top_rectangle                from '../assets/images/top-rectangle.svg';
import bottom_rectangle             from '../assets/images/bottom-rectangle-3.svg';

export default function Sertificates_descr_1({data, lang}) {
  const [urls, setUrls] = useState([]);

  function generateArrOfUrls() {
    const newArray = [];
    data.forEach((item, index) => {
      if (index === 0 || index === 1) return;
      if (item.url) newArray.push(item.url);
      if (item.images.length > 0) {
        for (const img of item.images) {
          newArray.push(img.path);
        }
      }
    });
    setUrls(newArray);
  }

  useEffect(() => {
    generateArrOfUrls();
  }, [data]);
  return (
    <section className={styles.sertificates_descr}>
      <img className={styles.top_rectangle} src={top_rectangle} alt="img" />
      <div className={`container ${styles.sertificates_descr_inner}`}>
        {/* <div className={styles.slider}><SlickSlider /></div>  */}
        <div className={`${styles.sertificates_descr_text} col-lg-8 col-md-7`}>
          {data.map((item, index) =>(
                <div key={index}>
                  <h2 className={styles.sertificates_descr_subtitle}
                      dangerouslySetInnerHTML={{__html: item.title}} />
                  <p className={styles.sertificates_descr_paragraph}
                     dangerouslySetInnerHTML={{__html: item.description}} />
                </div>
              )
          )}
        </div>
        <VerticalSlickSlider urls={urls} lang={lang} />
      </div>
      <img className={styles.bottom_rectangle} src={bottom_rectangle} alt="img" />
    </section>
  );
}  
