import PartnersDescr from '../components/PartnersDescr';
import OurPartners from '../components/OurPartners';
import Footer from '../components/Footer_2';
import React, {Component, useEffect, useState} from 'react';
import hero from '../utils/hero';
import Hero from '../components/Hero';
import * as axios              from 'axios';
import {useTranslation} from 'react-i18next';
import CDN from '..';

export default function Main() {
  const [PartnersData, setData] = useState();
  const {t, i18n: {language}} = useTranslation();

  async function fetchPatners() {
    await axios
      .get('get/partners')
      .then((res) => {
        setData(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  useEffect(() => {
    fetchPatners();
    window.scrollTo(0, 0)
  }, []);
  console.log('Partners data', PartnersData);
  // TODO configure parners api
  return (
    <>
      {PartnersData?.first && <Hero text={PartnersData.first.title} img={CDN + PartnersData.first.image} bottom={hero.partners.bottom} />}
      {PartnersData?.data && <PartnersDescr data={PartnersData.data.main_block[0]}/>}
      {PartnersData?.data && <OurPartners data={PartnersData.data.second_block}/>}
      <Footer /> 
    </>
  );
}
