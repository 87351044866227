export const translatedTexts = {
  ru: {
    next:'Следующий',
    prev:'Предыдущий',
    presentation: 'Презентация',
    mainEngeneer: 'Главные инженеры проектов',
    president: require('../src/assets/president_ru.jpg'),
    logotip: require('../src/assets/log_ru.jpg'),
    footerLogo: require('../src/assets/log_ru.png'),
    main_title: 'Проектирование вашей мечты - наша работа!',
    departments: {
      departments_title: 'Производственные отделы',
      mining: {
        title: 'Горный',
      },
      metallurgy: {
        title: 'Обогащения, металлургии и экологии',
        altText: '',
      },
      architecture: {
        title: 'Архитектурно-строительный',
        altText: '',
      },
      transport: {
        title: 'Генплана и транспорта',
        altText: '',
      },
      electrotechnical: {
        title: 'Электротехнический',
        altText: '',
      },
      electrotechnical_mobile: {
        title: 'Электро-технический',
        altText: '',
      },
      plumbing: {
        title: 'Сантехнический',
        altText: '',
      },
      economic: {
        title: 'Технико-экономический',
        altText: '',
      },
      automation: {
        title: 'Автоматизации проектных работ',
        altText: '',
      },
    },
    siteNavs: {
      history: {
        title: 'История',
        altText: '',
      },
      aboutCompany: {
        title: 'О компании',
        altText: '',
      },
      projects: {
        title: 'Проекты',
        altText: '',
      },
      partners: {
        title: 'Партнеры',
        altText: '',
      },
      management: {
        title: 'Руководство',
        altText: '',
      },
      resources: {
        title: 'Новости',
        altText: '',
      },
      certificates: {
        title: 'Сертификаты и Политика качества',
        altText: '',
      },
      vacancies: {
        title: 'Карьера',
        altText: '',
      },
    },
    contacts: {
      leaveAContacts: 'Оставьте ФИО и номер телефона. Наш специалист свяжется с вами',
      askQuestion: '',
    },
    submit: 'Отправить',
    copyright: 'ТОО Казгипроцветмет @ 2022 Все права защищены',
    maps:{
      title: 'Карта объектов',
      legends:{
        one:'Строящиеся объекты',
        two:'Построенные объекты',
        three:'Проектируемые объекты',
      },
      points: {
        p1: 'ТОО «КАЗЦИНК»<br />Жайремский ГОК. Горно-металлургическое производство',
        p2: 'ТОО «КАЗЦИНК»<br />УК МК. Реконструкция обжигового цеха цинкового завода',
        p3: 'ОАО «УГМК – Холдинг»<br />Цинковый завод в г. Кировограде',
        p4: 'ОАО «Екатеринбургский завод по обработке цветных металлов»  <br />Перенос производства в г. Верхняя Пышма',
        p5: 'ЗАО «Кыштымский медеэлектролитный завод»<br />Установка плавильных печей Ausmelt',
        p6: 'ТОО «Сары-Арка Спецкокс»<br />Установка по производству спецкокса',
        p7: 'Uhdenora S.p.A<br />Установка по производству хлора и каустической соды',
        p8: 'ТОО «KAZACHMYS Project LLC»<br />Бозшакольский горно-обогатительный комбинат, производительностью 20 млн. тонн руды в год',
        p9: 'АО ФИК «Алел»<br />Месторождение «Суздальское». Золотоизвлекательная фабрика',
        p10: 'ТОО «Шалкия цинк LTD»<br />Рудник «Шалкия», производительностью 4 млн. тонн руды в год',
        p11: 'АО «Соколовско-Сарбайское горно-обогатительное производственное объединение»<br />Завод по производству горячебрикетированного железа, производительностью 1,4 млн тонн',
        p12: 'ТОО «КазГеоруд»<br />Месторождение «Лиманное», производительностью 1,5 млн тонн руды в год',
        p13: 'ПАО ГМК «Норильский Никель»<br />Комплекс непрерывного конвертирования медного завода',
        p14: 'QUINZ AG<br />Быстринский ГОК. Обогатительная фабрика, производительностью 10 млн. тонн руды в год',
        p15: 'KAZ Minerals<br />Месторождение «Бозымчак». Подземный рудник, производительностью 1 млн. тонн руды в год',
        p16: 'KAZ Minerals<br />Месторождение «Артемьевское». Подземный рудник, производительностью 1,5 млн. тонн руды в год',
        p17: 'АО ФИК «Алел»<br />Отделение обогащения главного корпуса обогатительной фабрики',
        p18: 'АО «ТНК «Казхром» Аксуский завод ферросплавов, филиал<br /> Установка печи постоянного тока Реновации цеха №6',
        p19: 'АО «Соколовско-Сарбайское горно-обогатительное производственное объединение»<br />Шахта «Соколовская», производительностью 5 млн тонн руды в год',
        p20: 'АО «Соколовско-Сарбайское горно-обогатительное производственное объединение»<br />17 секция корпуса медного магнитного обогащения',
        p21: 'АО «Соколовско-Сарбайское горно-обогатительное производственное объединение»<br /> Рудоподготовительный комплекс до 60 млн тонн руды в год',
        p22: 'ТОО «NOVA-ЦИНК»<br />Месторождение «Акжал». Подземный рудник, производительностью 1,2 млн. тонн руды в год',
        p23: 'ТОО «Шалкия цинк LTD»<br />Обогатительная фабрика. Технологическая часть (пос. Шалкия)',
        p24: 'АО «ТНК Казхром» Актюбинский завод ферросплавов, филиал<br/>Опытно-промышленная установка по переработке отвальных распадающихся шлаков',
        p25: 'ТОО «КазГеоруд»<br/>Месторождение «Кундызды», производительностью 2 млн тонн руды в год',
        p26: 'АО «ТНК «КазХром» Донской ГОК, филиал<br/> Шахта «10-летия Независимости Казахстана», производительностью 6 млн. тонн руды в год',
        p27: 'АО «ТНК «КазХром» Донской ГОК, филиал<br/> Реконструкция отделения обогащения мелких классов',
        p28: 'ТОО «Kazakhmys Aktogay»<br/>Месторождение «Актогай». Установка кучного выщелачивания, производительностью 25 тыс тонн катодной меди в год',
        p29: 'ТОО «Kazakhmys Aktogay»<br/>Обогатительная фабрика, производительностью 25 млн тонн руды в год',
        p30: 'ТОО «KAZACHMYS Project LLC»<br/>Рудник «Акбастау», производительностью 2 млн тонн руды в год'
      }
    }
  },
  en: {
    next:'Next',
    prev:'Previous',
    presentation: 'Presentation',
    mainEngeneer: 'Chief Project Engineers',
    president: require('../src/assets/president_en.jpg'),
    logotip: require('../src/assets/logo_en.jpg'),
    footerLogo: require('../src/assets/logo_en.png'),
    main_title: 'Designing your dreams is our job!',
    departments: {
      departments_title: 'Production departments ',
      mining: {
        title: 'Mining',
      },
      metallurgy: {
        title: 'Enrichment, metallurgy and ecology',
        altText: '',
      },
      architecture: {
        title: '',
        altText: '',
      },
      transport: {
        title: '',
        altText: '',
      },
      electrotechnical: {
        title: '',
        altText: '',
      },
      plumbing: {
        title: '',
        altText: '',
      },
      economic: {
        title: '',
        altText: '',
      },
      automation: {
        title: '',
        altText: '',
      },
    },
    siteNavs: {
      history: {
        title: 'History',
        altText: '',
      },
      aboutCompany: {
        title: 'About company',
        altText: '',
      },
      projects: {
        title: 'Projects',
        altText: '',
      },
      partners: {
        title: 'Partners',
        altText: '',
      },
      management: {
        title: 'Leadership',
        altText: '',
      },
      resources: {
        title: 'Resources',
        altText: '',
      },
      certificates: {
        title: 'Certificates and Quality Policy',
        altText: '',
      },
      vacancies: {
        title: 'Career',
        altText: '',
      },
    },
    contacts: {
      leaveAContacts: '',
      askQuestion: '',
    },
    submit: 'Submit',
    copyright: 'LLP Kazgiprotsvetmet @ 2022 All rights reserved',
    maps:{
      title: 'Object map',
      legends:{
        one:'Objects under construction',
        two:'Built objects',
        three:'Designed objects<img id="lightbox-img',
      },
      points: {
        p1: 'KAZZINC LLP<br />Zhayremsky GOK. Mining and metallurgical production',
        p2: 'KAZZINC LLP<br />MC MK. Reconstruction of the roasting shop of the zinc plant',
        p3: 'OJSC UMMC – Holding<br />Zinc plant in Kirovograd',
        p4: 'Yekaterinburg Non-ferrous Metals Processing Plant OJSC <br />Transfer of production to Verkhnyaya Pyshma',
        p5: 'Kyshtym Copper Electrolyte Plant CJSC<br />Installation of Ausmelt melting furnaces',
        p6: 'Sary-Arka Specialcox LLP<br />Installation for the production of special coke',
        p7: 'Uhdenora S.p.A<br />Chlorine and caustic soda production plant',
        p8: 'KAZACHMYS Project LLC LLP<br />Bozshakol mining and Processing plant, with a capacity of 20 million tons of ore per year',
        p9: 'JSC FIC "Alel"<br />The Suzdalskoye field. Gold Extraction Factory',
        p10: 'Shalkia Zinc LTD LLP<br />Shalkia mine, with a capacity of 4 million tons of ore per year',
        p11: 'JSC "Sokolovsko-Sarbayskoye Mining and Processing Production Association"<br />Hot briquetted iron production plant with a capacity of 1.4 million tons',
        p12: 'KazGeorud LLP<br />Limannoye deposit, with a capacity of 1.5 million tons of ore per year',
        p13: 'PJSC MMC Norilsk Nickel<br />The complex of continuous conversion of the copper plant',
        p14: 'QUINZ AG<br />Bystrinsky GOK. Processing plant with a capacity of 10 million tons of ore per year',
        p15: 'KAZ Minerals<br />The Bozymchak deposit. Underground mine with a capacity of 1 million tons of ore per year',
        p16: 'KAZ Minerals<br />The Artemyevskoye field. Underground mine with a capacity of 1.5 million tons of ore per year',
        p17: 'JSC FIC "Alel"<br />Enrichment Department of the main building of the enrichment plant',
        p18: 'JSC "TNK "Kazchrome" Aksu ferroalloy Plant, branch<br /> Installation of a DC furnace Renovation shop No. 6',
        p19: 'JSC "Sokolovsko-Sarbayskoye Mining and Processing Production Association"<br />Sokolovskaya mine, with a capacity of 5 million tons of ore per year',
        p20: 'JSC "Sokolovsko-Sarbayskoye Mining and Processing Production Association"<br />Section 17 of the copper magnetic enrichment housing',
        p21: 'JSC "Sokolovsko-Sarbayskoye Mining and Processing Production Association"<br /> Ore preparation complex up to 60 million tons of ore per year',
        p22: 'NOVA-ZINC LLP<br />The Akzhal deposit. Underground mine with a capacity of 1.2 million tons of ore per year',
        p23: 'Shalkia Zinc LTD LLP<br />Processing plant. Technological part (village of Shalkia)',
        p24: 'JSC "TNK Kazchrome" Aktobe Ferroalloy Plant, branch<br/>Pilot plant for the processing of waste decaying slag',
        p25: 'KazGeorud LLP<br/>Kundyzdy deposit, with a capacity of 2 million tons of ore per year',
        p26: 'JSC TNK Kazchrome Donskoy GOK, branch of the 10th Anniversary of Independence of Kazakhstan Mine, with a capacity of 6 million tons of ore per year',
        p27: 'JSC "TNK "Kazchrome" Donskoy GOK, branch<br/> Reconstruction of the department of enrichment of small classes',
        p28: 'Kazakhmys Aktogay LLP<br/>The Aktogay deposit. Heap leaching plant with a capacity of 25 thousand tons of cathode copper per year',
        p29: 'Kazakhmys Aktogay LLP<br/>Processing plant with a capacity of 25 million tons of ore per year',
        p30: 'KAZACHMYS Project LLC LLP<br/>Akbastau mine, with a capacity of 2 million tons of ore per year'
      }
    }
  },
  kz: {
    next:'Келесі',
    prev:'Алдыңғы',
    presentation: 'Презентация',
    mainEngeneer: 'Жобаның бас инженерлері',
    president: require('../src/assets/president_ru.jpg'),
    logotip: require('../src/assets/log_ru.jpg'),
    footerLogo: require('../src/assets/log_ru.png'),
    main_title: 'Армандарыңды жобалау – біздің жұмысымыз!',
    departments: {
      departments_title: 'Өндірістік бөлімдер',
      mining: {
        title: 'Таулы',
      },
      metallurgy: {
        title: 'Байыту, металлургия және экология',
        altText: '',
      },
      architecture: {
        title: '-',
        altText: '',
      },
      transport: {
        title: '-',
        altText: '',
      },
      electrotechnical: {
        title: '-',
        altText: '',
      },
      plumbing: {
        title: '-',
        altText: '',
      },
      economic: {
        title: '-',
        altText: '',
      },
      automation: {
        title: '-',
        altText: '',
      },
    },
    siteNavs: {
      history: {
        title: 'Тарих',
        altText: '',
      },
      aboutCompany: {
        title: 'Компания туралы',
        altText: '',
      },
      projects: {
        title: 'Жобалар',
        altText: '',
      },
      partners: {
        title: 'Серіктестер',
        altText: '',
      },
      management: {
        title: 'Басшылық',
        altText: '',
      },
      resources: {
        title: 'Жаңалықтар',
        altText: '',
      },
      certificates: {
        title: 'Сертификаттар және сапа саясаты',
        altText: '',
      },
      vacancies: {
        title: 'Мансап',
        altText: '',
      },
    },
    contacts: {
      leaveAContacts: '',
      askQuestion: '',
    },
    submit: 'Жіберу',
    copyright: 'ЖШС Казгипроцветмет @ 2022 Барлық құқықтар қорғалған',
    maps:{
      title: 'Объект картасы',
      legends:{
        one:'Салынып жатқан нысандар',
        two:'Салынған нысандар',
        three:'Жобаланған объектілер',
      },
      points: {
        p1: '"КАЗЦИНК" ЖШС < br / >Жәйрем КБК. Тау-кен металлургия өндірісі',
        p2: '"КАЗЦИНК" ЖШС<br />ҚК ЖК. Мырыш зауытының күйдіру цехын қайта құру',
        p3: '"УМК – Холдинг"ААҚ<br />Кировоградтағы мырыш зауыты',
        p4: '"Екатеринбург Түсті металдарды өңдеу зауыты" ААҚ <br />өндірісті көшіру Жоғарғы Пышма',
        p5: '"Кыштым медеэлектролит зауыты" ЖАҚ < br / >Ausmelt балқыту пештерін орнату',
        p6:'"Сары-Арқа арнайы кокс"ЖШС<br />арнайы кокс өндірісі бойынша қондырғы',
        p7: 'uhdenora S. p. a<br />хлор және каустикалық сода өндіретін қондырғы',
        p8: '"KAZACHMYS Project LLC"ЖШС<br />Бозшакөл тау-кен байыту комбинаты, өнімділігі жылына 20 млн. тонна кен',
        p9: '"Алел"ФИК АҚ<br />"Суздальское" кен орны. Алтын шығаратын зауыт',
        p10: '"Шалқия мырыш LTD"ЖШС<br />"Шалқия" кеніші, өнімділігі жылына 4 млн. тонна кен',
        p11:'"Соколов-Сарыбай тау-кен байыту өндірістік бірлестігі" АҚ<br />өнімділігі 1,4 млн тонна ыстық крикетті темір өндіру зауыты',
        p12: '"Қазгеоруд"ЖШС<br />"Лиманое" кен орны, өнімділігі жылына 1,5 млн тонна кен',
        p13: '"Норильск Никель" ТМК жақ < br />мыс зауытының үздіксіз түрлендіру кешені',
        p14: 'QUINZ AG<br / > Быстринский ГОК. Байыту фабрикасы, өнімділігі жылына 10 млн. тонна кен',
        p15: 'Kaz Minerals<br />"Бозымшақ" кен орны. Өнімділігі жылына 1 млн. тонна кенді құрайтын жерасты кеніші',
        p16: 'Kaz Minerals<br />"Артемьевское" кен орны. Өнімділігі жылына 1,5 млн. тонна кенді құрайтын жерасты кеніші',
        p17: '"Алел"ФИК АҚ<br />байыту фабрикасының бас корпусын байыту бөлімшесі',
        p18:'"Қазхром "ТҰК" АҚ Ақсу ферроқорытпа зауыты, филиал<br / > №6 цехтың тұрақты ток жөндеу пешін орнату',
        p19: '"Соколов-Сарыбай тау-кен байыту өндірістік бірлестігі "АҚ<br />" Соколовская "шахтасы, өнімділігі жылына 5 млн тонна кен',
        p20: '"Соколов-Сарыбай тау-кен байыту өндірістік бірлестігі" АҚ < br / >17 мыс магнитті байыту корпусының секциясы',
        p21: '"Соколов-Сарыбай тау-кен байыту өндірістік бірлестігі" АҚ < br /> Кен дайындау кешені жылына 60 млн тоннаға дейін',
        p22: '"Nova-мырыш"ЖШС<br />"Ақжал"кен орны. Өнімділігі жылына 1,2 млн. тонна кенді құрайтын жерасты кеніші',
        p23: '"Шалқия мырыш LTD" ЖШС<br />байыту фабрикасы. Технологиялық бөлім (Шалқия кенті)',
        p24:'"Қазхром ТҰК" АҚ Ақтөбе ферроқорытпа зауыты,<br/>филиалы үйінді ыдырайтын қождарды қайта өңдеу бойынша тәжірибелік-өнеркәсіптік қондырғы',
        p25: '"Қазгеоруд"ЖШС<br/>"Құндызды" кен орны, өнімділігі жылына 2 млн тонна кен',
        p26: 'Дон КБК" Қазхром " ТҰК " АҚ, "Қазақстан Тәуелсіздігінің 10 жылдығы" шахтасының филиалы, өнімділігі жылына 6 млн. тонна кен',
        p27: 'Дон КБК" Қазхром " ТҰК "АҚ, филиал < br/> шағын сыныптарды байыту бөлімшесін қайта құру',
        p28: '"Kazakhmys Aktogay"ЖШС<br/>"Ақтоғай"кен орны. Өнімділігі жылына 25 мың тонна катодты мыс үймелі шаймалау қондырғысы',
        p29: '"Kazakhmys Aktogay"ЖШС<br/>байыту фабрикасы, өнімділігі жылына 25 млн тонна кен',
        p30: '"Kazachmys Project LLC"ЖШС<br/>"Ақбастау" кеніші, өнімділігі жылына 2 млн тонна кен'
      }
    }
  },
};
