import styles from '../styles/President.module.css';
import president from '../assets/images/president.jpg';
import React,{ useState } from 'react';
import {useTranslation} from 'react-i18next';
import {translatedTexts} from '../translatedTexts';
import CDN from '..';

export default function CompanyPresident({ data }) {
  const {t, i18n: {language: lang}} = useTranslation();
  const [isOpen,setState]=useState(false);
  const showImage = () => {
    setState(true);
  };
  const hideLightBox = () => { 
    setState(false);
  }; 
  return (
    <>
    <section className={styles.company_president}>
      <div className={`container ${styles.company_president_inner}`}>
        <div className={`${styles.company_president_text}`}>
          <p style={{ color: '#013977' }} className={styles.company_president_paragraph} dangerouslySetInnerHTML={{ __html: data.title }} />
          {data?.descriptions.map((el, index) => (
            <p key={index} className={styles.company_president_paragraph}
              dangerouslySetInnerHTML={{ __html: el }} />
          ))}
        </div>
        <div onClick={() => showImage()} className={`${styles.company_president_img}`}>
        <img src={translatedTexts[lang].president} /></div>
        {/* {data?.images.map((el) => (<img src={CDN + el.path} />))}</div> */}
      </div>
    </section>
    {
    isOpen && (
      <div className={styles.lightbox} onClick={() => hideLightBox()}>
        <img src={translatedTexts[lang].president} />
      </div> 
    )
  }</>
  );
}
