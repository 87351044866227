import Slider from '../components/Slider';
import SiteNav from '../components/SiteNav';
import Map from '../components/Map';
import Footer from '../components/Footer_1';
import React, { useState, useEffect} from 'react';
import *as axios from 'axios';
import top_rectangle from '../assets/images/top-rectangle.svg';
import {useTranslation} from 'react-i18next';
import {translatedTexts} from '../translatedTexts';

 

export default function Main() {
  const [MainData, setMainData] = useState([]);
  const {t, i18n} = useTranslation();
  const {language} = i18n;
  useEffect(() => {
    async function fetchData() {
      await axios.get('get/main-pages').then(res => {
          setMainData(res.data.data)
      }).catch(e => {
          console.log(e)
      })
  }
  fetchData(); 
    window.scrollTo(0, 0)
  }, []);
 
  return (
    <>
    {MainData.length !== 0 ?
            <>
      <Slider />
      <h1 style={{textAlign: 'center', color: '#094689'}} dangerouslySetInnerHTML={{ __html: MainData.second[0].big_title }} >{}</h1>
      <SiteNav data={MainData.second}/> 
      <Map data={MainData} top_rectangle={top_rectangle} />
      <Footer /> 
            </>: 
            <div/>}
    </>
  );
}
