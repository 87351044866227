import React, {useEffect, useState} from 'react';
import {Link}                       from 'react-router-dom';
import styles                       from '../styles/ProductionDepartments.module.css';
import *as axios from 'axios';
import bottom_rectangle from '../assets/images/bottom-rectangle.svg';
import CDN from '..';


export default function ProductionDepartments() { 
  const [titles,setTitles]=useState()
  useEffect(()=>{
      axios.get('get/command-types').then(res=>{
          setTitles(res.data)
      })
  },[])

  const links=['mining-department','metallurgy','architecture','transport','electrotechnical','plumbing','economic','automation']
  return ( 
    <section className={styles.cards}> 
      <div className="container">
        {titles ? <h2 className={styles.cards_title}>{titles.big_title}</h2> : <h2></h2>}
        <div className={styles.cards_inner}>
          {titles&&titles.data.map((slide, index)=>( 
            <Link className={styles.cards_link} to={`${links[index]}`}>
              <span>{slide.title}</span>
              <div className={styles.mountain}><img src={CDN + slide.icon} /></div>
            </Link>
            )      
          )}
        </div>
      </div>
      <img className={styles.bottom_rectangle} src={bottom_rectangle} alt={'img'} />
    </section>
  );
}
