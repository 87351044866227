import React, {useState} from 'react';
import CDN from '..';
import styles from '../styles/ManagementSystem.module.css';
import '../styles/VerticalSlickSlider.css';
import {translatedTexts} from '../translatedTexts';


export default function ManagementSystem({data, lang}) {
  const [isOpen,setIsOpen]=useState(false);
  const [image,setImage]=useState();

  const showImage = (image) => {
    setImage(image);
    setIsOpen(true);
  };

  const hideLightBox = () => {
    setIsOpen(false);
  };

  const showNext = (e) => {
    e.stopPropagation();
    let currentIndex = data.images.indexOf(image);
    if (currentIndex >= data.images.length - 1) {
      setIsOpen(false);
    } else {
      let nextImage = data.images[currentIndex + 1];
      setImage(nextImage)
    }
  };

  const showPrev = (e) => {
    e.stopPropagation();
    let currentIndex = data.images.indexOf(image);
    if (currentIndex < 3) {
      setIsOpen(false);
    } else {
      let nextImage = data.images[currentIndex - 1];
      setImage(nextImage)
    }
  };

  return (
    <section className={styles.management_system}>
      <div className={`container ${styles.management_system_inner}`}>
        <div className={`col-lg-7 col-md-9 ${styles.management_system_text}`}>
          {data?.title && <h2 className={styles.management_system_title} dangerouslySetInnerHTML={{__html: data.title}} />}
          {data?.description && <p className={styles.management_system_descr_2} dangerouslySetInnerHTML={{__html: data.description}} />}
        </div>
        <div className={styles.management_system_right}>
              <button  className={styles.management_system_img_1}  onClick={() => showImage(data?.images[0].img)}>
                <img src={CDN + data?.images[0]?.path} alt={'slider'} />
              </button>  
          {/* {data?.images &&
            data.images[0].map((img,index) => (
              <button key={index} className={styles.management_system_img_1}  onClick={() => showImage(img)}>
                <img src={CDN + img.path} alt={'slider'} />
              </button>  
            ))
          } */}
        </div>
        {isOpen && (
          <div id="lightbox" onClick={() => hideLightBox()}>
            <button className="prev" onClick={(e) => showPrev(e)}>
              {translatedTexts[lang].prev}
            </button>
            <img id="lightbox-img" src={CDN + data?.images[0]?.path} alt="certificate" />
            <button className="next" onClick={(e) => showNext(e)}>
              {translatedTexts[lang].next}
            </button>
          </div>
        )} 
      </div>
    </section> 
  );
}
