import Hero from '../components/Hero';
import DepartmentManagement from '../components/DepartmentManagement';
import MetallurgyMain from '../components/MetallurgyMain';
import Footer from '../components/Footer_1';
import hero from '../utils/hero';
import {useTranslation} from 'react-i18next';
import {useEffect, useState} from 'react';
import CDN from '..';
import *as axios from 'axios';

export default function MiningDepartment() {
	const [data, setData] = useState({});
	const {t, i18n} = useTranslation();
	useEffect(() => {
					async function fetchData() {
									await axios.get('get/command?type=metallurgy').then(res => {
											setData(res.data)
									}).catch(e => {
													console.log(e)
									})
					}
					fetchData();
					window.scrollTo(0, 0)
	}, [])
  return (
    <>
      {data.first && <Hero text={data.first.title} img={CDN+data.first.image} bottom={hero.metallurgy.bottom} />}
      <MetallurgyMain data={data}/>
      <Footer />
    </>
  );
}
