import Hero from '../components/Hero'
import DepartmentManagement from '../components/DepartmentManagement'
import AutomationMain from '../components/AutomationMain'
import Footer from '../components/Footer_1'
import hero from '../utils/hero'
import *as axios from 'axios';
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CDN from '..'

export default function MiningDepartment(){
	const [data, setData] = useState({});
	const {t, i18n} = useTranslation();
	useEffect(() => {
					async function fetchData() {
									await axios.get('get/command?type=automation').then(res => {
											setData(res.data)
									}).catch(e => {
													console.log(e)
									})
					}
					fetchData();
					window.scrollTo(0, 0)
	}, [])
    return(
        <>
								{data.first && <Hero text={data.first.title} img={CDN+data.first.image} bottom={hero.mining.bottom} /> }
            <AutomationMain data={data}/>
            <Footer/>
        </>
    )
}