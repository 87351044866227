import CompanyManagement from '../components/CompanyManagement';
import Footer from '../components/Footer_2';
import hero from '../utils/hero';
import Hero from '../components/Hero';
import {useEffect, useState} from 'react';
import CDN from '..';
import *as axios from 'axios';

export default function Sertificates() {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
        await axios.get('get/career').then(res => {
          setData(res.data)
        }).catch(e => {
            console.log(e)
        })
    }
    fetchData(); 
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    {data.length !== 0 ?
        <>
      <Hero text={data.first.title} img={CDN + data.first.image} bottom={hero.management.bottom} />
      <CompanyManagement stuff={data} />
      <Footer />
            </>: 
            <div/>}
        </> 
  );
}
