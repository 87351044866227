import Footer from '../components/Footer_2'
// import ProjectName from '../components/ProjectName'
// import ReferenceList from '../components/ReferenceList'
// import Map from '../components/Map'
import Industry from '../components/Industry'
import React, { useState, useEffect } from "react"
import *as axios from 'axios';
import top_rectangle from '../assets/images/top-rectangle-2.svg'
import hero from '../utils/hero'
import Hero from '../components/Hero'
import { useTranslation } from 'react-i18next'
import CDN from '..';

export default function Projects({languages}) {
		const { t, i18n } = useTranslation();
    const [projectData, setProjectData] = useState([]);
    useEffect(() => {
        async function fetchData() {
            await axios.get('get/plan?block=gorn').then(res => {
                setProjectData(res.data)
            }).catch(e => {
                console.log(e)
            })
        }
        fetchData();
        window.scrollTo(0, 0)
    }, [])
    console.log("Projects", projectData)
    return (
        <>
            {projectData.length !== 0 ?
                <div>
                    <Hero text={projectData.first.title} img={CDN + projectData.first.image} bottom={hero.projects.bottom}/>
                    {/* <ProjectName data={projectData.main_block} lang={i18n.language}/>
                    <ReferenceList data={projectData.second_block} lang={i18n.language}/>
                    <Map top_rectangle={top_rectangle}/> */}
                    <Industry/>
                    <Footer />  
                </div>
                :
                <div></div> 
            }
        </>
    )
}

